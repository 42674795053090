<template>
  <div>
    <Breadcrumbs title="Free Cards"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <div class="m-t-6 col-12 col-md-6">
                  <b-input-group>
                    <b-form-input v-model="filter" placeholder="Type to Search" class="border btn-square" autofocus></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''" class="btn btn-square border" variant="primary">Clear</b-button>
                      <b-button @click="fetchData" class="btn btn-square border" variant="light">Refresh</b-button>
                      <b-button v-show="checkUserPermissions(user,['create_free_cards'])" @click="showCreateModal" class="btn btn-square border" variant="success">New Free Card</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <div class="m-t-10 col-12 col-md-6 text-md-right">
                  <b-form-group label-cols="9" label="Per Page">
                    <b-form-select class="btn-square border text-center" v-model="perPage" :options="pageOptions"></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <div class="table-responsive datatable-vue">
                <b-table
                    ref="table"
                    show-empty
                    selected-variant="success"
                    :items="tableRecords"
                    :fields="tableFields"
                    :filter="filter"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @filtered="onFiltered"
                    @row-selected="rowSelected"
                >
                  <template class="text-right" #cell(student)="row">
                    {{ row.item.student.first_name }} {{ row.item.student.last_name }}
                  </template>
                  <template class="text-right" #cell(start_at)="row">
                    {{ getPaymentMonth(row.item.start_at) }}
                  </template>
                  <template class="text-right" #cell(end_at)="row">
                    {{ getPaymentMonth(row.item.end_at) }}
                  </template>
                  <template class="text-right" #cell(created_at)="row">
                    {{ new Date(row.item.created_at).toDateString() }}
                  </template>
                  <template #cell(status)="row">{{ statusOptions[row.item.status].text }}</template>
                  <template #cell(action)="row" class="text-right">
<!--                    <b-button v-show="checkUserPermissions(user,['update_free_cards'])" class="btn btn-block btn-square" variant="success" @click="showEditModal(row.index, row.item, $event.target)">Edit</b-button>-->
                    <b-button v-show="checkUserPermissions(user,['delete_free_cards'])" class="btn btn-block btn-square" variant="danger" @click="showDeleteModal(row.index, row.item, $event.target)">Remove</b-button>
                  </template>
                </b-table>
              </div>


              <b-row>
                <b-col cols="4">
                  Records: <strong>{{ totalRows }}</strong>
                </b-col>
                <b-col cols="8" class="my-1" v-if="totalRows">
                  <b-pagination
                      align="right"
                      class="my-0"
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      pills
                  ></b-pagination>
                </b-col>
              </b-row>



              <!-- Begin Delete Modal -->
              <b-modal id="delete_modal" title="Delete" cancel-variant="default" ok-variant="danger" ok-title="Confirm" @hide="clearFormModals" @ok="submitDeleteModal">
                <h6 class="m-b-10">Please confirm removal of following record:</h6>
                <strong v-text="selected.name"></strong>
              </b-modal>
              <!-- End Delete Modal -->

              <!-- Begin Create Modal -->
              <b-modal id="form_modal" :title="form_modal_title" cancel-variant="default" ok-variant="primary" :ok-title="form_modal_ok_title" @hide="clearFormModals" @ok.prevent="submitFormModal">

                <b-form-group v-if="freeCardForm.id" label="Id">
                  <b-input v-if="form_modal_id === 'form_modal_create'" v-model="freeCardForm.id"></b-input>
                  <b-input v-else v-model="freeCardForm.id" :readonly="true"></b-input>
                </b-form-group>

                <b-form-group label="Student NIC">
                  <b-input :readonly="freeCardForm.id !== null" @change="changedNIC" v-model="freeCardForm.student_nic"></b-input>
                </b-form-group>

                <b-form-group label="Institute *">
                  <b-form-select @change="changedInstitute" :disabled="freeCardForm.student_id===null" v-model="freeCardForm.institute_id" :options="institutes" value-field="id" text-field="text" class="border">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Select an option --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>

                <b-form-group label="Class *">
                  <b-form-select :disabled="!freeCardForm.institute_id" v-model="freeCardForm.class_detail_id" :options="classes" value-field="id" text-field="text" class="border">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Select an option --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>

                <div class="form-row">
                  <div class="col-lg-6">
                    <b-form-group label="Start Date">
                      <month-picker-input
                          v-model="freeCardForm.start_at"
                          :default-year="default_year"
                          :default-month="default_month"
                          :input-pre-filled="true"
                          alignment="top"
                          style="z-index: 5;width: 100%;"
                      ></month-picker-input>
                    </b-form-group>
                  </div>
                  <div class="col-lg-6">
                    <b-form-group label="End Date">
                      <month-picker-input
                          v-model="freeCardForm.end_at"
                          :default-year="default_year"
                          :default-month="default_month"
                          :input-pre-filled="true"
                          alignment="top"
                          style="z-index: 5;width: 100%;"
                      ></month-picker-input>
                    </b-form-group>
                  </div>
                </div>

                <b-form-group label="Remarks (optional)">
                  <b-textarea v-model="freeCardForm.remark" :state="hasError('remark')" ></b-textarea>
                  <b-form-invalid-feedback :state="hasError('remark')">{{getError('remark')}}</b-form-invalid-feedback>
                </b-form-group>

              </b-modal>
              <!-- End Create Modal -->


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {checkUserPermissions} from '@/plugins/functions'
import { mapGetters, mapState } from 'vuex'
import CRUD_API from '@/services/crud'
// import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import API from "@/services/api";
import moment from "moment";
export default {
  name: "free_cards",
  // components: { VueBootstrapTypeahead },
  computed: {
    ...mapState({ user: state => JSON.parse(state.auth.user) }),
    ...mapGetters({
      free_card_types: 'select/free_card_types',
      // institutes: 'select/institutes'
    })
  },
  data() {
    return {
      errorBag: null,
      filter: '',
      totalRows: 0,
      perPage: 5,
      currentPage: 1,
      pageOptions: [5,10,25,50,100],
      tableRecords: [],
      selected: [],

      api_base: '/api/backend/pages/free-cards',
      tableFields: [
        { key: 'id', label: 'ID', sortable: true, },
        { key: 'institute.name', label: 'Institute', sortable: true, },
        { key: 'student', label: 'Student', sortable: true, },
        { key: 'class_detail.name', label: 'Class Name', sortable: true, },
        { key: 'free_card_type.name', label: 'Card Type', sortable: true, },
        // { key: 'student.username', label: 'Student', sortable: true, },
        { key: 'start_at', label: 'Start Date', sortable: true, },
        { key: 'end_at', label: 'End Date', sortable: true, },
        { key: 'created_at', label: 'Created Date', sortable: true, },
        { key: 'remark', label: 'Remarks (Optional)', sortable: true, },
        { key: 'status', label: 'Status', sortable: true, },
        { key: 'action', label: 'Action', sortable: false, class: 'text-right text-capitalize' }
      ],
      statusOptions: [
        { value: 0, text: "Active" },
        { value: 1, text: "Inactive" },
      ],


      // Form Modal
      form_modal_id: '',
      form_modal_title: '',
      form_modal_ok_variant: 'primary',
      form_modal_ok_title: '',

      institutes: [],
      classes: [],
      classSearch: '',
      selectedClass: null,

      // Free Card Data
      default_year: parseInt(moment().format('Y'), 10),
      default_month: parseInt(moment().format('M'), 10),
      freeCardForm: {
        id: null,
        student_id: null,
        institute_id: null,
        class_detail_id: null,
        free_card_type_id: null,
        start_at: {
          // monthIndex: null,
          from: null,
          to: null,
          month: null,
          year: null
        },
        end_at: {
          // monthIndex: null,
          from: null,
          to: null,
          month: null,
          year: null
        },
        remark: '',
        status: 0
      },
    }
  },
  watch: {
    currentPage() {
      this.fetchData()
    },
    perPage() {
      this.currentPage = 1
      this.fetchData()
    },
    filter() {
      this.currentPage = 1
      this.fetchData()
    },
    //classSearch: function (cls) { this.fetchClasses(cls) }
    // start_at(){
    //   return moment(String(this.selected.start_at)).format('DD-MM-YYYY')
    // },
  },
  mounted() {
    this.$store.dispatch('select/fetchRecords', { id: 'free-card-types' })
    this.fetchData()
  },
  methods: {
    checkUserPermissions,
    clearErrors(){ this.errorBag = []; },
    hasError(ifn){ if (this.errorBag) { return !this.errorBag.hasOwnProperty(ifn); } return null; },
    getError(ifn){ if(this.errorBag) { if (!this.hasError(ifn)) { return this.errorBag[ifn][0]; } } return null; },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.items.count = filteredItems.length
    },
    rowSelected(item) {
      this.selected = item
    },
    clearSearch() {
      this.filter = ''
      this.currentPage = 1
      this.fetchData()
    },
    handlePageChange(value) {
      //alert(value)
      this.perPage = value
      this.fetchData()
    },
    handlePageSizeChange(value) {
      this.currentPage = value
      this.fetchData()
    },
    getPaymentMonth(val){
      return moment(val).format('YYYY - MMM')
    },
    setStartMonth(date) {
      this.payment_month = date
    },
    setEndMonth(date) {
      this.payment_month = date
    },
    async fetchData() {
      await CRUD_API.index( this.api_base, {
        url: this.api_base,
        params: {
          per_page: this.perPage,
          page: this.currentPage,
          filter: this.filter
        }
      }).then(function(resp) {
        this.tableRecords = []
        //this.currentPage = resp.data.data.current_page
        //this.perPage = resp.data.data.per_page
        this.totalRows = resp.data.data.total
        this.tableRecords = resp.data.data.data
      }.bind(this)).catch((err) => {
        console.log(err)
        this.$toasted.error("Error occurred while retrieving data")
      }).finally(() => {
        this.$refs.table.refresh()
      })
    },

    clearFormModals() {
      this.selected = [];
    },
    // Create Record Modal
    showCreateModal(button) {
      this.classes = []
      this.institutes = []
      this.selected = { id: null, name: null, status: null, institute_id: null, free_card_type_id: null }
      this.freeCardForm.id = null
      this.freeCardForm.start_at = {
        from: moment().format('YYYY-MM-01'),
        to: moment().format('YYYY-MM-01'),
        month: parseInt(moment().format('M'), 10),
        year: parseInt(moment().format('Y'), 10),
        monthIndex: parseInt(moment().format('M'), 10)
      }
      this.freeCardForm.end_at = {
        from: moment().format('YYYY-MM-01'),
        to: moment().format('YYYY-MM-01'),
        month: parseInt(moment().format('M'), 10),
        year: parseInt(moment().format('Y'), 10),
        monthIndex: parseInt(moment().format('M'), 10)
      }

      this.selectedClass = null
      this.institutes = null
      this.form_modal_title = "New Free Card "
      this.form_modal_ok_title = 'Submit'
      this.$root.$emit('bv::show::modal', 'form_modal', button)
    },
    submitFormModal() {
      if (this.selected.id) {
        this.submitEditModal()
      } else {
        this.submitCreateModal()
      }
    },
    submitCreateModal() {
      // this.selected['class_detail_id'] = this.selectedClass.id;
      this.selected['status'] = 0;
      CRUD_API.create(this.api_base, this.freeCardForm)
          .then((resp) => {
            if(resp.data.success) {
              this.$toasted.success(resp.data.message)
            } else {
              this.$toasted.error(resp.data.message)
            }
          })
          .catch((err) => {
            console.log('ERROR_CREATE_FREE_CARD', err)
            this.$toasted.error(err.message)
            // this.$toasted.error("Error occurred while saving data")
          })
          .finally(() => {
            this.fetchData()
          })
    },
    // Edit Record Modal
    showEditModal(index, item, button) {
      console.log(item)
      this.selected = item
      this.selected.student_nic = item.student.nic
      this.institutes = []
      this.fetchInstitutes()
      this.selected.institute_id = item.institute_id
      this.selected.start_at = moment(item.start_at).utc().add(item.start_at.utcOffset(), 'm').toISOString()
      this.fetchClasses();
      // this.$refs['selectedClass'].val(item.class_detail.name)
      this.form_modal_title = "Edit Free Card "
      this.form_modal_ok_title = 'Update'
      this.$root.$emit('bv::show::modal', 'form_modal', button)
    },
    submitEditModal(){
      CRUD_API.update(this.api_base, this.selected.student_id, this.selected)
          .then((resp) => {
            alert(JSON.stringify(resp))

            this.$toasted.success(resp.data.message)
          })
          .catch((err) => {
            console.log(err)
            this.$toasted.error(err.data.message)
          })
          .finally(() => {
            this.fetchData()
          })
    },
    // Delete Record Modal
    showDeleteModal(index, item, button) {
      this.selected = item
      this.$root.$emit('bv::show::modal', 'delete_modal', button)
    },
    submitDeleteModal(){
      CRUD_API.delete(this.api_base, this.selected.id)
          .then((resp) => {
            console.log(resp)
            this.$toasted.success("Free Card removed successfully")
          })
          .catch((err) => {
            console.log(err)
            this.$toasted.error("Error occurred while removing data")
          })
          .finally(() => {
            this.fetchData()
          })
    },


    changedNIC() {
      this.institutes = []
      API.student_by_nic('/api/backend', {
        nic: this.freeCardForm.student_nic,
        status: 0
      }).then(result => {
        if(result.data.success) {
          this.selected.student_id = result.data.data
          this.freeCardForm.student_id = result.data.data.id
          this.freeCardForm.institute_id = null
          this.fetchInstitutes()
        } else {
          this.$toasted.error(result.data.message)
        }
      }).catch(error => {
        console.log(error)
        this.$toasted.error("System Error")
      })
    },
    changedInstitute() {
      this.classes = []
      this.fetchClasses();
    },
    fetchInstitutes() {
      API.select("student-institutes", {
        nic: this.freeCardForm.student_nic
      }).then((response) => {
        this.institutes = response.data.data
      })
    },
    fetchClasses() {
      API.select('classes', {
        institute_id: this.freeCardForm.institute_id,
        student_id: this.freeCardForm.student_id,
        select_mode: 'assigned',
      }).then((response) => {
        this.classes = response.data.data
      }).catch((error) => {
        this.classes = []
        console.log(error)
        this.$toasted.error("Error occurred while removing classes.")
      })
    }
  }
}
</script>

<style scoped>

</style>
